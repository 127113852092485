
.parent-menu{
  background-color: black !important;
  width: 210px;
  color: white !important;
}

.drop-down{
  background-color: black !important;
  color: white;

}

.test{
  /* background-color: coral !important; */
}

.expand-icon{
  /* background-color: red !important; */
  color: white !important;
  width: 20px !important;
  /* margin-left: -40px !important; */
}



.listitemIcon{
  
  /* background-color: red; */
}

.listitemIcon img{
 
  width: 20px !important;
}

.typo{
  margin-left: -20px !important;
  font-size: small !important;
}

.expand{
  width:110% !important
}


.sub-menu{
  padding-left: 12px;
}


/* .sub-menu{
  background-color: black !important;
  color: white !important;
}

.accordian{
  background-color: rgb(65, 63, 63) !important;
  color: white !important;
  
} */

.menu-active{
  background-color: blue !important;
  width: 100%;
  color: white !important;
}