/* .section2 .dropdown-toggle .select{
    margin-left: 18rem;

} */

.input input{
    width: 100% !important;
}

.model_bg{
    /* background-color: red!important; */
    height: 60vh;
    width: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    justify-items: baseline;
    margin-left: 20% !important;
}