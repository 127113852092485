@tailwind base;
@tailwind components;
@tailwind utilities;

.table_css{
height: 500px !important;
}

.table_small_css{
height: 250px !important;
}


.tableHeader{
    background-color: #F1F1F1;
    font-weight: bold !important;
   }

 
.dropdown {
    border-left: 1px solid transparent;
    border-right: 1px solid transparent;
  }
  
.cursor{
    cursor: pointer;
}

.app_bg_color1{
    background-color: #E5E5E5 !important;
}

.app_bg_color2{
    background-color: #E7ECFF !important;
}

.app_blue_color{
    background-color: #476EF8;
}

.heading{
    font-size: 17px;
}
.subheading{
    font-size: 16px;
}
.paragraph{
    font-size: 12px;
}




.status_color1{
    background-color: #476EF8;
}


.status_color2{
    background-color: red;
}

.status_color3{
    background-color: green;
}

.status_color4{
    background-color: rgb(186, 150, 5);
}

.status_color5{
    background-color: rgb(222, 172, 7);
}
.status_color5{
    background-color: rgb(204, 4, 226);
}

.status_color6{
    background-color: rgb(251, 111, 4);
}
.status_color7{
    background-color: rgb(92, 41, 203);
}
.status_color8{
    background-color: rgb(100, 11, 66);
}

.status_color9{
    background-color: rgb(220, 152, 6);
}

.status_color10{
    background-color: rgb(8, 37, 8);
}





.color1{
    color: #476EF8;
}


.color2{
    color: red;
}

.color3{
    color: green;
}

.color4{
    color: rgb(186, 150, 5);
}


.color5{
    color: rgb(204, 4, 226);
}

.color6{
    color: rgb(251, 111, 4);
}
.color7{
    color: rgb(92, 41, 203);
}
.color8{
    color: rgb(100, 11, 66);
}

.color9{
    color: rgb(220, 152, 6);
}

.color10{
    color: rgb(8, 37, 8);
}
