


.name {
  color: #007bff
}

.cursor:hover {
  color: blue
}

.cursor {
  cursor: pointer
}

.textarea {
  resize: none
}