.input_height input {
    height: 7rem !important;

 
}

.tax-page{
    z-index: 0;

}


.input_height2 input {
    height: 20rem !important;
 
}

.input_3 input {
    width: 8rem !important;
    height: 3.4rem !important;

}

.name_input input{
    height: 3.4rem !important;
    width: 20rem !important;
   



}

.tax{

    z-index: 900;
}


.css-1633um2-MuiPaper-root{

box-shadow: none !important;
text-align: start !important;
}



.name_input-c input{
    height: 3.4rem !important;
    width: 20rem !important;
}


.plain_input input {
    height: 3.5rem !important;
    width:30rem !important;

 
}