

.MuiDataGrid-cell{
    /* margin-left: 10px; */
    /* background-color: red; */
    /* align-items: center; */
    text-align: center;
}

.MuiDataGrid-columnHeaderTitleContainer{
    /* background-color: rgb(152, 158, 152) !important; */
}

.MuiDataGrid-columnHeaders{
    background-color: #E5E5E5;
    /* margin-left: 15px; */
}

.MuiDataGrid-cellContent{
    /* align-items: center;
    text-align: center;
    background-color: red; */
}

