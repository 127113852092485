.imageParent img{
    width:100%;
    max-width:400px;
    border-radius: 20px;
    padding-bottom: 10px;
}

.imageParent label{
    padding: 10px;
    background: #476EF8; 
    display: table;
    color: #fff;
    margin-left: 45px;
    font-size: 10px;
    border-radius: 5px;
 
    
     }



     .imageParent input[type="file"] {
    display: none;
  
}


