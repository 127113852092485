/* .required-description input{
    width: 43rem !important;
  
} */

/* .required-description-two input{
    width: 10rem !important;
  
} */
/* 
.required-description{
    margin-left: 20px;
} */


.profile_section_one{
    display: flex;
    justify-content: space-between;
}



.profile_section_one input{
    width: 32rem !important;
}


.description input{
    height: 10rem !important;
}

.required-description-two input{
    width: 15.5rem !important;
}

.required-quantity-two input{
    width: 5rem !important;
}

