.profile .profileImage{
    border-radius: 10px;
    height: 150px;
    box-shadow: 3px 3px 1px #ccc;
    -webkit-box-shadow: 3px 3px 1px #ccc;
    -moz-box-shadow: 3px 3px 1px #ccc;
    margin-top: 19px;
}


.profile input[type="file"]{
	display:none;
}

.progile label{
	width:150px;
	height:150px;
	border-radius:10px;
	background-color:#ddd;
    display:block;
    cursor:pointer;
}


.profile .profilesmImage img{
    height: 40px;
    margin-top: 2px;

}

.profile_section{
    position: relative;
}


.profile_parent .profile{
    position: absolute;
    top: 0;
    right: 0;
}


.stock_information  .btn {
    border: 1px solid #476EF8;
    background-color: white;
    color: #476EF8;
    padding: 5px 28px;
    font-size: 12px;
    cursor: pointer;
  }

  .table_parent span{
      color:#45444B;
      font-size: 13px;
  }

  .grand span{
    color:#45444B;
    font-size: 12px;
}

.chart img{
   width: 500px;
   margin-left: 80px;
   margin-top: 30px;
   margin-bottom: 50px;
  
}

.open_stock span{
    color: #476EF8;
    font-size: 13px;
    font-weight: 400;
}

.open_stock_info span{
    color: #45444B;
    font-size: 12px;
}

.open_stock_info .text{
    color: #45444B;
    /* opacity: 0.5; */
    font-size: 12px;
    font-weight: bold;
}

.open_stock .box_image{
    display: flex;
    flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 150px;
   height: 150px;
   border-radius: 1px solid #C4C4C4;
}

.open_stock .box_image .zero{
    font-size: 20px;
    color: #476EF8;
    font-weight: bold;
 }

 .open_stock .box_image .qty{
    font-size: 13px;
    color: #476EF8;
    /* opacity: 0.7; */
   
 }


 .reorder-point{
     background-color: #F1F1F1;
     width: 140px;
     height: 50px;
     border-radius: 5px;
 }

 .reorder-point h5{
    color: #45444B;
    font-size: 10px;
   
}

 .reorder-point span{
     color: #45444B;
     font-size: 10px;
    
 }





  

  /* Green */
  /* .stock_information .success {
    border-color: #04AA6D;
    color: green;
  } */



