/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.homepage-group {
  display: flex;
 
  height: 100vh;
}

.homepage-group.layout {

  position: relative;
  overflow: hidden;
  min-height: 100px;
  flex-grow: 1;
}
.button {
  background-color: #486ef8 !important;
  color: aliceblue !important;
  border: 2px solid black;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
}

.default-btn {
  /* border: 2px solid black; */
  background-color: white;
  color: black;
  /* padding: 14px 28px; */
  /* font-size: 16px; */
  cursor: pointer;
}
.default {
  border-color: #e7e7e7;
  color: black;
}

.default:hover {
  background: #e7e7e7;
}

.homepage-flex {
  display: flex;
  flex-direction: column;
}

.homepage-flex.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 90%;
  margin: 26px auto;
}

.homepage-flex1 {
  display: flex;
}

.homepage-flex1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 91.36%;
  margin: 45px auto 0px;
}

.homepage-image136 {
  background: var(--src) center center/contain no-repeat;
}

.homepage-image136.layout {
  position: relative;
  flex: 0 1 121px;
  height: 85px;
  width: 121px;
  min-width: 121px;
}

.homepage-flex1-spacer {
  flex: 0 1 752px;
}

.homepage-flex1-item {
  display: flex;
  position: relative;
  flex: 0 1 135px;
}

.homepage-group9 {
  display: flex;
  background-color: white;
  border-radius: 8px 8px 8px 8px;
}

.homepage-group9.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  flex-grow: 1;
  margin: 15px 0px 16px;
}

.homepage-highlights2 {
  display: flex;
  justify-content: center;
  font: 600 16px/1.2 "Open Sans", Helvetica, Arial, serif;
  color: #486ef8;
  text-align: center;
  letter-spacing: 3.2px;
}

.homepage-highlights2.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  flex-grow: 1;
  margin: 16px 29px 18px;
}

.homepage-flex1-spacer1 {
  flex: 0 1 16px;
}

.homepage-flex1-item1 {
  display: flex;
  position: relative;
  flex: 0 1 160px;
}

.homepage-group8 {
  display: flex;
  background-color: #476ef8;
  border-radius: 8px 8px 8px 8px;
}

.homepage-group8.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  flex-grow: 1;
  margin: 15px 0px 16px;
}

.homepage-highlights1 {
  display: flex;
  justify-content: center;
  font: 600 16px/1.2 "Open Sans", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  letter-spacing: 3.2px;
}

.homepage-highlights1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  flex-grow: 1;
  margin: 16px 29.5px 18px;
}

.homepage-flex2 {
  display: flex;
  flex-direction: row-reverse;
}

.homepage-flex2.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 95.68%;
  margin: 32px 0% 0px 4.32%;
}

.homepage-flex2-item {
  display: flex;
  position: relative;
  flex: 1 1 445px;
}

.homepage-flex3 {
  display: flex;
  flex-direction: column;
}

.homepage-flex3.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  flex-grow: 1;
  margin: 126px 0px 181px;
}

.homepage-hero-title {
  font: 800 48px/1.2 "Open Sans", Helvetica, Arial, serif;
  color: #45444c;
  letter-spacing: 3.4px;
}

.homepage-hero-title.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}

.homepage-highlights {
  font: 16px/1.2 "Open Sans", Helvetica, Arial, serif;
  color: #45444c;
  letter-spacing: 0.8px;
}

.homepage-highlights.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 54px 16px 0px 0px;
}

.homepage-group1 {
  display: flex;
  background-color: #476ef8;
  border-radius: 8px 8px 8px 8px;
}

.homepage-group1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 44.72%;
  margin: 40px 55.28% 0px 0%;
}

.homepage-flex2-spacer {
  flex: 0 1 163px;
}

.homepage-flex2-item1 {
  display: flex;
  position: relative;
  flex: 0 1 632px;
}

.homepage-image227 {
  display: flex;
}

.homepage-image227.layout {
  /* position: relative; */
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  flex-grow: 3;
}

/* .homepage-image228 {
  width: 100%;
  height: auto;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
} */

/* .homepage-image228.layout {
  position: relative;
  flex-grow: 1;
} */

/* MEDIA QUERY */

@media (max-width: 480px) {
  .homepage-flex2 {
    display: inline-block;
    flex-direction: column-reverse;
  }

  img.homepage-image228 {
    height: 20rem;
    width: 30rem;
    margin-left: 0.5rem;
  }

  .homepage-group.layout {
    height: 0;
    min-height: 800px;
    flex-grow: 1;
  }

  .homepage-flex3 {
    display: flex;
    flex-direction: column;
    bottom: 5rem;
  }
}

@media screen and (min-device-width: 481px) and (max-device-width: 768px) {
  .homepage-flex2 {
    display: flex;
    flex-direction: row-reverse;
  }

  img.homepage-image228 {
    height: 20rem;
    width: 30rem;
    margin-left: 5rem;
  }

  .homepage-group.layout {
    height: 0;
    min-height: 800px;
    flex-grow: 1;
  }

  .homepage-flex3 {
    display: flex;
    flex-direction: column;
    bottom: 5rem;
    width: 20rem;
  }

  .homepageflex3-parent {
    padding-left: -50rem;
  }

  .homepage-hero-title {
    font: 800 38px/1.2 "Open Sans", Helvetica, Arial, serif;
    color: #45444c;
    letter-spacing: 1.4px;
  }
}

@media screen and (min-device-width: 769px) and (max-device-width: 1200px) {
  .homepage-flex2 {
    display: flex;
    flex-direction: row-reverse;
  }

  img.homepage-image228 {
    height: 20rem;
    width: 30rem;
    margin-left: 5rem;
  }

  .homepage-group.layout {
    height: 0;
    min-height: 800px;
    flex-grow: 1;
  }

  .homepage-flex3 {
    display: flex;
    flex-direction: column;
    bottom: 5rem;
    width: 20rem;
  }

  .homepageflex3-parent {
    padding-left: -50rem;
  }

  .homepage-hero-title {
    font: 800 38px/1.2 "Open Sans", Helvetica, Arial, serif;
    color: #45444c;
    letter-spacing: 2.4px;
  }
}
