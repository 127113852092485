
.small-pic{
    width: 250px !important;
    height: 250px !important;
    max-width : 250px !important;
    max-width : 250px !important;
    object-fit: fill;
    border-radius: 5px;
  
   
  }


  .small-pic2{
    width: 100px !important;
    height: 100px !important;
    max-width : 250px !important;
    max-width : 250px !important;
    object-fit: fill;
    border-radius: 5px;
  
   
  }