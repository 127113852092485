@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@700&display=swap');

body {
  font-family: "Open Sans", sans-serif;

  
}
/* 
h1,h2,h3,p,span{
  color:"#45444B";
}




.App {
  text-align: center;
}



.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.color_1 {
  background-color: green;
  color: white;
}

.color_2 {
  background-color: red;
  color: white;
}
.color_3 {
  background-color: rgb( 221, 196, 10);
  color: white;
}
.color_4 {
  background-color: rgb(10, 84, 221);
  color: white;
}

.color_5 {
  background-color: rgb(14, 199, 199);
  color: black;
}

.color_6 {
  background-color: rgb(248, 129, 10);
  color: white;
}

.color_7 {
  background-color: rgb(189, 248, 10);
  color: rgb(8, 8, 8);
}

.color_9 {
  background-color: rgb(32, 9, 243);
  color: white;
}

.color_10 {
  background-color: rgb(146, 9, 243);
  color: white;
}

.color_11 {
  background-color: rgb(4, 68, 113);
  color: white;
}



/* for Scrollbars and tabs */

.color_sidebar_side_1 {
  color: green;
}

.color_sidebar_side_2 {
  color: red;
}
.color_sidebar_3 {
  color: rgb( 221, 196, 10);
}
.color_sidebar_4 {
  color: rgb(10, 84, 221)white;
}

.color_sidebar_5 {
  color: rgb(14, 199, 199);
}

.color_sidebar_6 {
  color: rgb(248, 129, 10);
}

.color_sidebar_7 {
  color: rgb(189, 248, 10);
}

.color_sidebar_9 {
  color: rgb(32, 9, 243);
}

.color_sidebar_10 {
  color: rgb(146, 9, 243);
}

.color_sidebar_11 {
  color: rgb(4, 68, 113);
}
/* End of  Scrollbars and tabs */

.show-cursor{
  cursor: pointer;
}
