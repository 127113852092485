.parent_image{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
}

.borderImage{
    width: 32%;
    position: absolute;
    top: 0;
}

.profile2{
    /* margin-top: 8px; */
}


.profilesmImage3{
margin-right: 190px;
}

