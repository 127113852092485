.name input{
    height: 3rem !important;
}

.description input{
    height: 10rem !important;
}

/* p{
    color: #B5B1CA;
} */

span{
    color: #45444B;
}


/* Important part */
.modal-dialog{
    overflow-y: initial !important
}
.modal-body{
    height: 80vh;
    overflow-y: auto;
}


.prefix input{
    width: 9rem !important;
}