
optgroup {
    background-color: #E5E5E5;
   


}

.datepicker{
    color: red !important;
}

.notification select{
height: 2.4rem;
width: 10rem;
border-radius: 3px;
border-color: #C4C4C4;
font-size: 13px !important;

}

input, value{
    color:#45444B;
    font-size: 15px !important;
}