.plan .plain_input input {
    height: 3.1rem !important;
    width:28rem !important;
   

 
}

.plan .plain_input_P input {
    height: 3.4rem !important;
    width:29rem !important;

}

.plan .plain_input_desc input{
    height: 7rem !important;
    width:30rem !important;

}

.plan .plain_input_F input {
    width:27.3rem !important;
    height: 3.5rem !important;
}




.plan  .input_height2 input {
    height: 15rem !important;
 
}

.plan  .input_3 input {
    width: 8rem !important;
    height: 3.4rem !important;

}

.plan  .name_input input{
    height: 3.4rem !important;
    width: 20rem !important;

}


.plan  .input_3 input {
    width: 8rem !important;
    height: 3.4rem !important;

}



.css-6j8wv5-Input input {
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}

/* 


#react-select-3-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}

#react-select-7-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}

#react-select-9-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}

#react-select-11-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}

#react-select-13-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}

#react-select-15-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}

#react-select-17-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}

#react-select-19-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}

#react-select-21-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}

#react-select-23-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}


#react-select-25-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}


#react-select-27-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}


#react-select-29-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
}


#react-select-5-input{
    width: 0!important;
    min-width: 0 !important;
    border: 0 !important;
    outline-style: none !important;
    border: 1px solid yellow !important;
    height: 0 !important;
    opacity: 0 !important;   
   
} */


.css-6j8wv5-Input{
  
    /* border: 1px solid green !important; */
}