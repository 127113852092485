/* Custom style for child rows */
.child-row {
    background-color: #dde8f9 !important; /* Light gray-blue background */
    font-style: italic;                   /* Italic text for distinction */
    color: #555;                          /* Softer text color */
    border: 2px;
    transition: background-color 0.3s;    /* Smooth hover transition */
  }
  
.parent-row {
    background-color: #f1fcf9 !important; /* Light gray-blue background */
    font-style: italic;                   /* Italic text for distinction */
    color: #555;                          /* Softer text color */
    border: 2px;
    transition: background-color 0.3s;    /* Smooth hover transition */
  }
  
  /* Optional: Highlight child row on hover */
  .child-row:hover {
    background-color: #e6f7ff !important; /* Slightly brighter on hover */
  }