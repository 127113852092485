
.scroll{
    overflow: auto;
max-height: 110vh;
/* background-color: red !important; */
}

.border-n{
    border: none !important;
 
}

.border{
    border: 1px solid gray;
}