



/* th {
  white-space: nowrap;

}

th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #F1F1F1 !important;
} */

.css-1qdprsr-MuiDataGrid-root{
  border: red !important;
}
