.profile_section .finishItem .select{
    width: 15rem !important;
}

.required-q input {
    height: 2.4rem;
    width: 10rem !important;
}

.required-q-2 input{
    height: 2.4rem;
    width: 39.4rem !important; 
}



.section-one{
    border-bottom: 3px solid #efefef;
    margin-bottom: 3px;
}

.bold-tem{
    font-weight: 300;
}