/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
.login-page-group {
  display: flex;
  background-color: white;
}

.login-page-group.layout {
  position: relative;
  overflow: hidden;
  min-height: 900px;
  flex-grow: 1;
}

button {
  color: #476ef8;
}

.login-page-group-item {
  display: flex;
  position: relative;
  flex: 1 1 665px;
}

.login-page-flex {
  display: flex;
  flex-direction: column;
}

.login-page-flex.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  flex-grow: 1;
  margin: 64px 0px 175px 128px;
}

.login-page-image {
  background: var(--src) center center/contain no-repeat;
  background-image: url("/src/assets/login_icon.png");
  width: 100%;
  height: 100%;
}

.login-page-highlights-box.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 63.13%;
  margin: 32px 36.87% 0px 0%;
}

.login-page-highlights {
  overflow: visible;
  margin-top: 0px;
  margin-bottom: 0px;
  margin: 0px;
  font: 16px/1.2 "Open Sans", Helvetica, Arial, serif;
  color: #45444c;
  letter-spacing: 0.8px;
  white-space: pre-wrap;
}

.login-page-group6 {
  display: flex;
}

.login-page-group6.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 105px 0px 0px;
}

.login-page-image29 {
  width: 100%;
  height: auto;
  -o-object-fit: contain;
  object-fit: contain;
  -o-object-position: center top;
  object-position: center top;
}

.login-page-image29.layout {
  position: relative;
  flex-grow: 1;
}

.login-page-group-spacer {
  flex: 0 1 115px;
}

.login-page-group-item1 {
  display: flex;
  position: relative;
  flex: 0 1 660px;
}

.login-page-group1 {
  display: flex;
  background-color: rgba(71, 110, 248, 0.011);
}

.login-page-group1.layout {
  position: relative;
  height: 900px;
  flex-grow: 1;
}

.login-page-group11 {
  display: flex;
  flex-direction: column;
}

.login-page-group11.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 71.52%;
  margin: 188px 21.21% 189px 7.27%;
}

.login-page-hero-title {
  font: 700 36px/1.2 "Open Sans", Helvetica, Arial, serif;
  color: #45444c;
  letter-spacing: 1.8px;
}

.login-page-hero-title.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}

.login-page-highlights4-box.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 21px 0px 0px;
}

.login-page-highlights4 {
  font: 600 16px/1.2 "Open Sans", Helvetica, Arial, serif;
  color: black;
  letter-spacing: 0.8px;
}

.login-page-highlights4-span0 {
  font: 600 1em/1.2 "Open Sans", Helvetica, Arial, serif;
  color: #45444cff;
  letter-spacing: 0.8px;
}

.login-page-highlights4-span1 {
  font: 600 1em/1.2 "Open Sans", Helvetica, Arial, serif;
  color: #486ef8ff;
  letter-spacing: 0.8px;
}

.login-page-group2 {
  display: flex;
  flex-direction: column;
}

.login-page-group2.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 32px 0px 0px;
}

.login-page-group3 {
  display: flex;
  flex-direction: column;
}

.login-page-group3.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}

.login-page-highlights1 {
  font: 16px/1.61 "Open Sans", Helvetica, Arial, serif;
  color: #45444c;
  letter-spacing: 0.8px;
}

.login-page-highlights1.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}

.login-page-group4 {
  display: flex;
  flex-direction: column;
}

.login-page-group4.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 32px 0px 0px;
}

.block {
  display: block;
  width: 100%;
  border: none;
  border-radius: 10px;
  background-color: #486ef8 !important;
  color: aliceblue !important;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.login-page-highlights3 {
  font: 16px/1.61 "Open Sans", Helvetica, Arial, serif;
  color: #45444c;
  letter-spacing: 0.8px;
}

.login-page-highlights3.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
}

.login-page-content-box {
  display: flex;
  background-color: white;
  border-radius: 8px 8px 8px 8px;
}

.login-page-content-box.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 8px 0px 0px;
}

.login-page-highlights5 {
  font: 16px/1.61 "Open Sans", Helvetica, Arial, serif;
  color: #45444c;
  letter-spacing: 0.8px;
}

.login-page-highlights5.layout {
  position: relative;
  flex: 0 0 auto;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 16px 0px 16px 16px;
}

.login-page-content-box-spacer {
  flex: 0 1 240px;
}

.login-page-highlights11 {
  display: flex;
  justify-content: flex-end;
  font: 16px/1.61 "Open Sans", Helvetica, Arial, serif;
  color: #8e8ba2;
  text-align: right;
  letter-spacing: 0.8px;
}

.login-page-highlights11.layout {
  position: relative;
  flex: 0 0 auto;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 16px 16px 16px 0px;
}

.login-page-group5 {
  display: flex;
  background-color: #476ef8;
  border-radius: 8px 8px 8px 8px;
}

.login-page-group5.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 64px 0px 0px;
}

.login-page-highlights21 {
  display: flex;
  justify-content: center;
  font: 600 16px/1.2 "Open Sans", Helvetica, Arial, serif;
  color: white;
  text-align: center;
  letter-spacing: 3.2px;
}

.login-page-highlights21.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  width: 77px;
  min-width: 77px;
  margin: 16px auto 18px;
}

.login-page-highlights31 {
  display: flex;
  justify-content: center;
  font: 600 16px/1.2 "Open Sans", Helvetica, Arial, serif;
  color: #486ef8;
  text-align: center;
  letter-spacing: 3.2px;
}

.login-page-highlights31.layout {
  position: relative;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  margin: 32px 0px 18px;
}
