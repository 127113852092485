input,
input::-webkit-input-placeholder {
    font-size: 12px;
    line-height: 3;
    color: #45444B;
    border-radius: 5px;
  
  
}

/* Change the input field width first before any adjustment  */

/* First Section CSS START */
/* 
width of Input */

input{
    width: 21.5rem;
    z-index: 1000;
    border-radius: 5px;
}
.item_a {
    width: 21.5rem !important;
 
}

/* This countrol category width */

.info_sec .select_dimension_manufaturer {
    width: 21.5rem  !important;
    border-radius: 5px;
}

/* This countrol the width of dimension */
.info_sec .select_dimension {
    width: 21.5rem  !important;
    border-radius: 5px;
}


/* This control input n group feilded */

.info_sec_group .select_dimension_manufaturer_group{
    width: 20.3rem  !important;
    border-radius: 5px;

}


/* this countrol dimension input inner box  */

.info_sec .select_dimension input {
    background-color: white;
    outline: none;
    width: 55px;
    height: 35px;
    border: none;
    margin-top: -6px;
 }


 /* First Section CSS END  */



 /* Other Information Section Start */


.info_sec .select {
    width: 250%  !important;
    border-radius: 5px;
  
}

.info_sec .select_unit {
    width: 247%  !important;
    border-radius: 5px;
  
}







.info_sec .select_dimension a {
    border-radius: 5px;
    padding-bottom: 10px;
    width: 10px;

    
}  


 ul .dropdown-menu{
    width: 50px!important;
    background-color: red;
}

.info_sec .select_dimension_weight {
    width: 16.8rem  !important;
    border-radius: 5px;
}

.info_sec .select_dimension_weight a {
    margin-left: 101px;
    border-radius: 5px;
}  




 .info_sec .select_dimension_manufacturer a {
    margin-left: 90px;
    border-radius: 5px;
} 


.info_sec .select_dimension_brand {
    width: 16.8rem  !important;
    border-radius: 5px;
} 

 .info_sec .select_dimension_branc a {
    margin-left: 90px;
    border-radius: 5px;
} 





.other_information  .select_sales{
    width: 16.8rem  !important;
    border-radius: 5px;
} 








 .other_information .placeHolder_input{
 padding-left: 90px;
}




i{
    color: #B5B1CA;
    font-size: 11px;
} 

/* deal with the MUI  */
.select_dimension .dimension{
    display: flex;
    align-items: center;
    justify-items: center;
    justify-content: space-between;
    margin-top: -2px !important;

}




.select_dimension .dimension .dimenSelect {
margin-top: -10px;
}



/* MANOVER THE  SELECT */

.dimension_input{
    position: relative;
}

.dimension_input .dimenSelect_input{
    position: absolute;
    top: -11px;
    right: -7px;
  
}


.other_dimension_input{
    position: relative;
}

.other_dimension_input .dimenSelect_input{
    position: absolute;
    top: -11px;
    left: -5px;
  
  
}



/* itemgroup page scroll */

.scroll_text{
    font-size: 13.8px;
    color: #45444B;
}


.info_group input{
    width: 31rem !important;
}