.main {
    overflow: scroll;
    height: 178vh !important;
  }
  
  .main2 {
    overflow: scroll;
    height: 154vh !important;
  }
  
  .main3 {
    overflow: scroll;
    height: 78vh !important;
  }
  
  
  
  
  .mainBill {
    overflow: scroll;
    /* height: 178vh !important; */
    height: 70% !important;
    overflow-y: scroll;
  }
  
  .profile_parent{
    display: flex;
    justify-items: center;
    justify-content: space-between;
    align-items: center;
    top:-400rem;
  
   
  
  
  }
  
  
  .section2{
    margin-top:-5% !important ;
  }
  
  
  .profile_section{
    position: relative;
  
  }
  
  .des{
    position: absolute;
    top: 430px;
  }
  
  .accountBorder{
    border: 1px solid #476EF8;
  }
  