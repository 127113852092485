.table .css-rtrcn9-MuiTablePagination-root {
    /* opacity: 0 !important; */
  

}

.planTwo .container{
    height: 0% !important;
}
 



.MuiTablePagination-displayedRows .css-levciy-MuiTablePagination-displayedRows{
    padding-top: 20rem !important;

}

 

.css-1fajery-MuiDataGrid-root .MuiDataGrid-cell {

    font-size: 12px;
    /* width: 20% !important;
    height: 5rem !important; */

}

.MuiDataGrid-columnHeaderTitleContainer{
   
}

.css-1fajery-MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer{
    /* background-color: red !important; */
    /* height: 1rem !important; */
    font-size: 13px;
}

.MuiTableCell-footer{
    background-color: red !important;
}


