

.tab{
    background-color: #E5E5E5 !important;
    margin-left: 10px !important;
    border-radius: 9px !important;
    font-size: 10px !important;
    font-weight: bold !important;
}

.tab-box{
    width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
    top: 0 !important;
    left: 0 !important;
}